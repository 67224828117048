<template>
  <nav>
    <div class="header-container">
      <h1>DIGITS</h1>
      <label for="checkbox" class="switch-label" @click="toggleTheme()">
        <span>🌙</span>
        <span>☀️</span>
        <div
            class="switch-toggle"
            :class="{ 'switch-toggle-checked': userTheme === 'dark-theme' }"
        ></div>
    </label>
    </div>
  </nav>
  <router-view/>
</template>
<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {
      userTheme: "light-theme",
    };
  },
  methods: {
    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },
    toggleTheme() {
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
      } else {
        this.setTheme("light-theme");
      }
    },
    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
          "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },
    getTheme() {
      return localStorage.getItem("user-theme");
    },
  },
  mounted() {
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    this.setTheme(initUserTheme);
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Podkova:wght@400;600;700&family=Quantico:ital,wght@0,400;0,700;1,400&display=swap');
:root {
  --font-family: 'Podkova', serif;
  --font-family-digits: 'Quantico', sans-serif;
  --primary-color: #1964A8;
  --secondary-color: #0D3559;
  --gray-color: #EFF4F8;
  --background-color: #F2F8FD;
  --opacity-color: #B9C7D4;
  --border-color: #1964a824;
  --start-color: #D373B18C;
  --stop-color: #B3D3738B;
  --element-size: 4rem;
  --text-color: var(--secondary-color);
  --invert: invert(0);
}
:root.dark-theme {
  --primary-color: #B9C7D4;
  --secondary-color: #F2F8FD;
  --gray-color: #333638;
  --background-color: #0D3559;
  --opacity-color: #1964A8;
  --invert: invert(1);
}
#app {
  font-family: var(--font-family);
  padding: 0 20px;
  color: var(--text-color);
}
body {
  background: var(--background-color);
}
nav {
  .header-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 28px;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: 0.14em;
      text-align: left;
    }
    .switch-label {
      /* for width, use the standard element-size */
      width: var(--element-size);

      /* for other dimensions, calculate values based on it */
      border-radius: var(--element-size);
      border: calc(var(--element-size) * 0.025) solid var(--primary-color);
      padding: calc(var(--element-size) * 0.1);
      font-size: calc(var(--element-size) * 0.3);
      height: calc(var(--element-size) * 0.35);

      align-items: center;
      background: var(--opacity-color);
      cursor: pointer;
      display: flex;
      position: relative;
      transition: background 0.5s ease;
      justify-content: space-between;
      z-index: 1;
    }
    .switch-toggle {
      position: absolute;
      background-color: var(--primary-color);
      border-radius: 50%;
      top: calc(var(--element-size) * 0.07);
      left: calc(var(--element-size) * 0.07);
      height: calc(var(--element-size) * 0.4);
      width: calc(var(--element-size) * 0.4);
      transform: translateX(0);
      transition: transform 0.3s ease, background-color 0.5s ease;
    }
    .switch-toggle-checked {
      transform: translateX(calc(var(--element-size) * 0.6)) !important;
    }
  }

}
</style>
